@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .button-bg-gradient {
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
    background: linear-gradient(266.09deg, #58EAFE -0.96%, #039AC9 97.63%);
  }
  .button-bg-gradient:hover {
    background: linear-gradient(266.09deg, #58EAFE -0.96%, #58EAFE 97.63%);
  }

  .button-bg-gradient-inverted {
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
    background: linear-gradient(264.34deg, #0081AA -11.49%, #131F3A 105.71%);
  }
  .button-bg-gradient-inverted:hover {
    background: linear-gradient(266.09deg, #0081AA -0.96%, #0081AA 97.63%);
  }

  .alternating-rows:nth-child(odd) {
    @apply bg-color-bg-light-row;
  }
  .alternating-rows:nth-child(even) {
    @apply bg-color-bg-dark-row;
  }

  .darkmode-input {
    background: rgba(255, 255, 255, 0.6);
  }

  .text-shadow-live {
    text-shadow: 0px 0px 20px #20FF65;
  }
  .text-shadow-fans {
    text-shadow: 0px 0px 16px rgba(83, 229, 251, 0.8)
  }
}

@layer components {
  .label-base {
    @apply block leading-6 text-color-text-label;
  }

  .input-base {
    @apply block w-full rounded-xl;
  }
  .input-lightmode {
    @apply bg-color-bg-input text-brand-blue-450 placeholder-color-text-muted;
  }
  .input-darkmode {
    @apply darkmode-input text-brand-gray-800 placeholder-color-text-muted-secondary;
  }
  .input-base-form {
    @apply input-base input-lightmode dark:input-darkmode;
  }
  .input-form {
   @apply px-6 py-3 input-base-form;
  }
  .input-form-inverted {
    @apply px-6 py-3 input-base input-darkmode font-medium;
  }

  .input-modal {
    @apply rounded-2xl bg-color-bg-team-input px-4 py-4 text-sm md:text-base md:px-6 md:py-[18px] text-color-text-input;
  }

  .button-focused {
    @apply focus:outline-none focus:ring-1 focus:ring-offset-2 focus:ring-color-text-primary;
  }
  .button-disabled {
    @apply disabled:opacity-50 disabled:cursor-not-allowed;
  }
  .button-base {
    @apply button-focused button-disabled text-center;
  }
  .button-rounded-large {
    @apply button-base font-semibold rounded-[20px] leading-none;
  }
  .button-rounded-small {
    @apply button-base rounded-xl font-semibold leading-none;
  }


  .button-background-primary {
    @apply button-bg-gradient text-color-text-button-primary;
  }
  .button-background-inverted {
    @apply button-bg-gradient-inverted text-color-text-primary;
  }
  .button-hollow-primary {
    @apply bg-transparent hover:bg-brand-blue-500 border-2 hover:text-color-text-button-primary border-brand-blue-500 text-brand-blue-500 transition-colors;
  }

  .button-large-primary {
    @apply inline-flex button-rounded-large button-background-primary px-12 items-center h-[60px];
  }
  .button-small-primary {
    @apply button-rounded-small button-background-primary px-12;
  }

  .button-large-inverted {
    @apply inline-flex button-rounded-large button-background-inverted px-12 items-center h-[60px];
  }
  .button-small-inverted {
    @apply button-rounded-small button-background-inverted px-12;
  }

  .button-small-hollow {
    @apply flex items-center button-base rounded-xl button-hollow-primary px-6 h-[44px];
  }
}

html, body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  font-family: 'Inter', sans-serif;
  @apply text-color-text-primary;
  @apply bg-color-bg-primary;

  /* NOTE: This is to remove the transparent blue box on tap to feel more native mobile */
  /* This is most needed in a capacitor application */
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}

h1, h2, h3 {
  font-family: 'Bai Jamjuree', 'Inter', sans-serif;
}

input, textarea, button {
  @apply focus:outline-none focus:ring-1 focus:ring-brand-blue-450;
}

*::selection {
  @apply text-color-bg-primary bg-[#A3F7A1]; /* WebKit/Blink Browsers */
}
::-moz-selection {
  @apply text-color-bg-primary bg-[#A3F7A1];
}